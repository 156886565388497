<template>
  <div class="imgDetail-page">
    <div class="page-bg-left"></div>
    <div class="page-bg-right"></div>
    <div class="main-container">
      <div class="page-header">
        <img class="back-btn" @click="goPage" src="../assets/images/back.png" alt="">

        <img src="../assets/images/logo.png" @click="goPage" class="logo" alt="">
        <div class="top-carousel">
          <Carousel v-model="value" loop>
            <CarouselItem v-for="(item,index) in bannerList" :key="index">
              <img class="img-carousel" :src="item.pc_image" alt="">
            </CarouselItem>
            <div v-if="bannerList.length === 0">暂无图片</div>
          </Carousel>
        </div>
      </div>

      <div class="wrap">
        <div class="top-mask" @click="openSlideModal"></div>
      <carousel-3d v-if="imgList.length" :display="7" :width="565" :height="420">
        <slide :index="index" v-for="(item, index) in imgList"  :key="'imgList' + index">
          <video  v-if="item.type == 'video'" autoplay width="100%" height="100%" controls>
            <source :src="item.src">
            您的浏览器不支持HTML5视频。
          </video>
          <img v-else style="width: 100%; height: 100%" :src="item.src" alt="">
        </slide>
      </carousel-3d>
      </div>

      <BottomBar></BottomBar>
    </div>


    <div class="spe-modal spe-bg" v-if="showModalPreview">
      <img class="spe-modal-close" src="../assets/images/modal-close.png" @click="closeModal" alt="">
      <div class="spe-modal-main">
        <div class="modal-preview">
          <div class="preview-main">
            <video v-if="previewItem.type == 'video'" autoplay width="100%" height="100%" controls>
              <source :src="previewItem.src">
              您的浏览器不支持HTML5视频。
            </video>
            <img v-else :src="previewItem.src" alt="">
          </div>
          <div class="preview-list">
            <Icon type="ios-arrow-back" @click="handleScroll('left')"/>

            <div class="preview-bar-out" ref="previewBar">
              <div class="preview-bar" :style="{width: (120 * imgList.length) + 'px'}">
                  <span class="preview-item"
                        :class="item.type"
                        @click="previewItem=item"
                        v-for="(item,index) in imgList"
                        :key="'modalImg' + index">
                    <Icon v-if="item.type=='video'" type="logo-youtube"/>
                    <img v-else :src="item.src" alt="">
                  </span>
              </div>
            </div>
            <Icon type="ios-arrow-forward" @click="handleScroll('right')"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import * as echarts from 'echarts'; // TODO 按模块引用

import BottomBar from "./comp/BottomBar";

export default {
  name: '',
  components: {
    BottomBar
  },
  data() {
    return {
      value: 0,
      btnType: 1,
      index: 0,
      //https://www.uu2018.com/webcjvue/1608.html

      // 图片预览
      showModalPreview: true,
      imgList: [
      ],
      previewItem: {

      },

      bannerList: [],
    }
  },

  mounted() {
    this.getBannerList()
    this.getImgOrVideoByPopList()
  },
  methods: {
    closeModal(){
      this.showModalPreview = false
    },
    openSlideModal() {
      this.showModalPreview = true
      console.log(index)
    },
    handleScroll(arrow) {
      let nav = this.$refs.previewBar
      // 定义滚动方向，其实也可以在赋值的时候写
      const moveForwardStep = 1;
      const moveBackStep = -1;
      // 定义滚动距离
      let step = 0;
      // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
      if (arrow == 'right') {
        step = moveForwardStep * 90;
      } else {
        step = moveBackStep * 90;
      }
      // 对需要滚动的元素进行滚动操作
      nav.scrollLeft += step;
    },

    handleChange(index) {
      // eslint-disable-next-line
      console.log(`[Demo] You have click cover ${index}`)
    },

    getBannerList() {
      let params = {
        pageSize: 5,
        type: 'banner'
      }
      this.AXIOS.get('/index/getBannerList', params).then(res => {
        this.bannerList = res.data
      }).finally(res => {
      })
    },

    getImgOrVideoByPopList() {
      let params = {
        pageSize: 10,
        type: 'popList'
      }
      this.AXIOS.get('/index/getImgOrVideo', params).then(res => {
        var arr = res.data || []
        this.imgList = arr
        this.previewItem = arr[0] || {}
        this.$forceUpdate()
      }).finally(res => {
      })
    },

    goPage() {
      this.$router.push('/')
    },
  }
}
</script>
<style lang="less">
.imgDetail-page {
  min-height: 100%;
  color: #fff;
  overflow-y: hidden;

  .page-bg-left {
    display: inline-block;
    width: 303px;
    height: 1100px;
    background: url("../assets/images/left.png") 100% 100%;
    position: fixed;
    left: -160px;
  }

  .page-bg-right {
    display: inline-block;
    width: 303px;
    height: 1100px;
    background: url("../assets/images/right.png") 100% 100%;
    position: fixed;
    right: -160px;
  }


  .spe-modal {
    display: inline-block;
    width: 850px;
    z-index: 111;
    height: 866px;
    background: url("../assets/images/modal-bg.png");
    background-size: 850px 866px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;

    &.spe-bg {
      width: 1020px;
      z-index: 111;
      height: 866px;
      background: url("../assets/images/modal-bg-border.png");
      background-size: 1020px 866px;
      padding: 46px;
      padding-top: 74px;
    }

    .icon-close {
      font-size: 40px;
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
    }

    .spe-modal-title {
      font-size: 28px;
      height: 60px;
      padding-left: 10px;
      padding-top: 10px;
      box-sizing: border-box;
    }

    .spe-modal-close {
      display: inline-block;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 87px;
      height: 91px;
      cursor: pointer;
    }

    .spe-modal-main {
      margin-top: 30px;
      box-sizing: border-box;
      height: 590px;
    }

    .modal-empty {
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      height: 700px;
      padding: 20px;
      overflow-y: scroll;
      background: #fff;
      color: #333;
      font-size: 16px;
    }

    .modal-list {
      box-sizing: border-box;
      height: 630px;
      overflow-y: scroll;
      overflow-x: hidden;

      .modal-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 20px;
        margin-bottom: 15px;
        box-sizing: border-box;
        background: #014761;
        font-size: 20px;
        cursor: pointer;

        &:hover {
          background: darken(#014761, 3%);
        }

        .icon-right {
          font-size: 30px;
        }
      }

    }


    .modal-list-notice {
      text-align: center;
      width: 100%;
      left: 0;
      position: absolute;
      bottom: 0;
      padding: 15px 20px;
      box-sizing: border-box;
      font-size: 14px;
      cursor: pointer;

      .icon-right {
        font-size: 30px;
      }
    }

    .modal-preview {
      .preview-main {
        width: 100%;
        height: 592px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .preview-list {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .preview-bar-out {
          width: 835px;
          overflow-x: hidden;
          overflow-y: hidden;

          margin-left: 15px;

          .preview-bar {
          }

        }

        .ivu-icon {
          font-size: 30px;
          width: 35px;
          cursor: pointer;
        }

        .preview-item {
          display: inline-block;
          width: 110px;
          height: 110px;
          border-radius: 5px;
          margin-right: 10px;
          border: 3px solid #10a4dc;
          cursor: pointer;
          box-sizing: border-box;
          float: left;

          &.video {
            line-height: 110px;
            text-align: center;
            background: #2baada;

            .ivu-icon {
              font-size: 50px;
              position: relative;
              left: -7px;
              top: 7px;
            }
          }

          &:hover {
            border: 3px solid #fff;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .main-container {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .top-btn {
      position: absolute;
      cursor: pointer;
      top: 155px;
      left: 0;
      width: 120px;
      height: 37px;
      line-height: 37px;
      border-radius: 5px;
      display: inline-block;
      background: url("../assets/images/btn-bg.png") 100% 100%;
      text-align: center;
      font-size: 16px;

      &:hover {
        background: url("../assets/images/btn-bg-active.png") 100% 100%;
      }
    }

    .wrap{
      position: relative;
      .top-mask{
        background: rgba(0,0,0,0);
        display: inline-block;
        width: 565px;
        height: 420px;
        position: absolute;
        left: 50%;
        transform:translate(-50%);
        z-index: 11;
      }
    }


    .back-btn {
      position: absolute;
      width: 59px;
      height: 59px;
      cursor: pointer;
      top: 155px;
      right: 0;
    }

    .page-header {
      width: 100%;
      height: 150px;
      box-sizing: border-box;
      margin-bottom: 110px;
      display: flex;
      position: relative;

      .logo {
        width: 403px;
        height: 150px;
        display: inline-block;
      }

      .top-carousel {
        width: 1070px;
        position: relative;

        .img-carousel {
          height: 150px;
          width: 100%;
        }
      }

    }
  }

}
</style>
